import { Controller } from '@hotwired/stimulus'
import ApexCharts from "apexcharts"

export default class extends Controller {
  static values = {
    url: String,
    height: Number,
    type: String,
    labelColor: String,
    borderColor: String,
    mainColors: Array,
    noDataText: String,
    formatCurrency: Boolean,
    displayXaxis: Boolean,
    displayYaxis: Boolean,
    displayDatalabels: Boolean,
    legendPosition: String,
    legendWidth: Number,
    legendHeight: Number,
    legendOffsetY: Number,
    offsetY: Number,
  }

  connect() {
    var element = this.element

    if (!element) {
      return;
    }

    var chart = new ApexCharts(element, this.options());
    chart.render();
    this.updateSeries(chart);
  }

  updateSeries(chart) {
    fetch(this.urlValue, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      }
    }).then(response => response.json())
      .then(response => {
        let seriesArray = undefined
        if (["pie", "donut"].includes(this.typeValue)) {
          seriesArray = response
          chart.updateOptions(seriesArray)
        } else {
          let keys = Object.keys(response)
          seriesArray = keys.map(key => {
            return {name: key, data: response[key]}
          })
        chart.updateSeries(seriesArray)
        }
      })
  }

  formatValue(val) {
    if (val === undefined) return "-"
    return this.formatCurrencyValue ? this.formattedPrice(val) : val
  }

  formattedPrice(val) {
    const formatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'EUR',
    })
    return formatter.format(val)
  }

  options() {
    return {
      series: [],
      chart: {
        offsetY: this.offsetYValue || 0,
        fontFamily: 'inherit',
        type: this.typeValue,
        height: this.heightValue,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false,
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 7,
          horizontal: false,
          columnWidth: ['40%'],
          borderRadiusApplication: 'end'
        },
        pie: {
          startAngle: 0,
          endAngle: 360,
          expandOnClick: true,
          offsetX: 0,
          offsetY: 0,
          customScale: 1,
          dataLabels: {
            offset: 0,
            minAngleToShowLabel: 10
          },
          donut: {
            size: '60%',
            borderRadius: 50,
            background: 'transparent',
            labels: {
              show: false,
              name: {
                show: true,
                fontSize: '22px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: undefined,
                offsetY: -10,
              }
            }
          }
        }
      },
      legend: {
        show: this.hasLegendPositionValue,
        position: this.legendPositionValue || "bottom",
        width: this.legendWidthValue,
        height: this.legendHeightValue,
        offsetY: this.legendOffsetYValue || 0
      },
      dataLabels: {
        enabled: this.displayDatalabelsValue || false
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 2
      },
      xaxis: {
        tickAmount: 'dataPoints',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: this.displayXaxisValue,
          style: {
            colors: this.labelColorValue,
            fontSize: '12px'
          }
        }
      },
      yaxis: {
        labels: {
          show: this.displayYaxisValue,
          style: {
            colors: this.labelColorValue,
            fontSize: '12px'
          }
        }
      },
      fill: {
        opacity: 1
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        hover: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: '12px'
        },
        y: {
          formatter: (val) => this.formatValue(val)
        }
      },
      colors: this.mainColorsValue,
      grid: {
        borderColor: this.borderColorValue,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      noData: {
        text: this.noDataTextValue
      }
    };
  }
}
