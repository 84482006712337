import InvoiceFiltersController from './invoice_filters_controller'

export default class extends InvoiceFiltersController {
  static targets = ['query', 'checkIn', 'checkOut', 'checkInToday', 'checkOutToday']

  connect() {
    super.connect()

    this.checkInTodayTarget.addEventListener('click', this.checkInToday.bind(this))
    this.checkOutTodayTarget.addEventListener('click', this.checkOutToday.bind(this))
  }

  checkInToday(e) {
    e.preventDefault()

    this.application.getControllerForElementAndIdentifier(this.checkInTarget, 'flatpickr').setToday()
    this.application.getControllerForElementAndIdentifier(this.checkOutTarget, 'flatpickr').clearValue()
  }

  checkOutToday(e) {
    e.preventDefault()

    this.application.getControllerForElementAndIdentifier(this.checkOutTarget, 'flatpickr').setToday()
    this.application.getControllerForElementAndIdentifier(this.checkInTarget, 'flatpickr').clearValue()
  }
}
