import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["closeBtn"]
  static values = { infoType: String }

  connect() {
    this.closeBtnTarget.addEventListener("click", () => this.closeClicked())
  }

  closeClicked() {
    let daysForExpire = 1
    const d = new Date();
    d.setTime(d.getTime() + (daysForExpire * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = `${this.infoTypeValue}=true;${expires};path=/`
  }
}
