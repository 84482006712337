import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static values = {
    url: String,
    clickboxId: String
  }

  connect() {
    window.addEventListener('click', this.handleClick.bind(this))
  }

  disconnect() {
    window.removeEventListener('click', this.handleClick.bind(this))
  }

  handleClick(e) {
    if (document.getElementById(this.clickboxIdValue).contains(e.target)){
      // Clicked in box
    } else{
      this.handleClickOutside()
    }
  }

  handleClickOutside() {
    fetch(this.urlValue, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    }).then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  }
}
