import { Controller } from '@hotwired/stimulus'
import { Tooltip } from 'bootstrap'

export default class extends Controller {
  connect() {
    const options = { html: true }

    if (this.element.dataset["bsContainer"] === 'self') {
      options.container = this.element
    }

    Tooltip.getOrCreateInstance(this.element, options)
  }

  disconnect() {
    Tooltip.getInstance(this.element)?.dispose()
  }
}
