import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  tabChanged(e) {
    let url = new URL(window.location.href)
    let searchParams = url.searchParams;
    searchParams.set('active', e.target.href.split("#")[1]);
    url.search = searchParams.toString()
    let newUrl = url.toString()
    window.history.replaceState("", e.target.href.split("#")[1], newUrl)
  }
}
