import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select", "number"]

  connect() {
    if (!this.hasSelectTarget || !this.hasNumberTarget) return

    this.selectTarget.addEventListener("change", this.selectChanged.bind(this))
    this.numberTarget.addEventListener("keyup", this.numberChanged.bind(this))
  }

  selectChanged() {
    this.numberTarget.value = this.selectTarget.value
  }

  numberChanged() {
    const choicesElement = this.selectTarget.closest('[data-controller="choices"]')
    const choicesController = this.application.getControllerForElementAndIdentifier(choicesElement, "choices")
    choicesController.choices.setChoiceByValue("")
    choicesController.choices.setChoiceByValue(this.numberTarget.value)
  }
}
