import { Controller } from '@hotwired/stimulus'
import Choices from 'choices.js'
import configMerge from '../config-merge'
import { get } from 'lodash'

export default class extends Controller {
  static targets = ['choices']

  static values = {
    options: Object
  }

  initialize() {
    this.choices = new Choices(this.choicesTarget, this.options())
  }

  connect() {
    this.choices.init()

    // this.choicesTarget.addEventListener('hideDropdown', this.fixBmd.bind(this))
    // allow the browser to initialize DOM before fixing BMD labels
    // setTimeout(this.fixBmd.bind(this), 0)
    // fix height for multi-line options
    setTimeout(() => {
      this.choices.setChoiceByValue(this.choicesTarget.value)
    }, 500)
  }

  disconnect() {
    // FIXME: workaround for Choices-js/Choices#1088
    const selectedValues = this.choices._currentState.items.map((item) => item.value)
    this.choices.destroy()

    selectedValues.forEach((selectedValue) => {
      const option = this.choicesTarget.querySelector(`option[value="${selectedValue}"]`)
      if (!option) {
        return
      }

      option.setAttribute('selected', 'selected')
    })

    // this.choicesTarget.removeEventListener('hideDropdown', this.fixBmd.bind(this))
  }

  // fixBmd() {
  //   this.choicesTarget.dispatchEvent(new Event('change'))
  // }

  options() {
    return configMerge([this.defaultOptions(), this.optionsValue])
  }

  defaultOptions() {
    const container = this.element

    return {
      // removeItemButton: true,
      allowHTML: true,
      shouldSort: false,
      itemSelectText: ' ',
      classNames: {
        containerOuter: `choices ${Array.from(this.choicesTarget.classList).join(" ")}`,
      },
      // Needed because by default Choices does not transfer data attributes
      // to the javascript elements Choices-js/Choices#738
      callbackOnCreateTemplates: function () {
        return {
          option: ({ label, value, customProperties, active, disabled }) => {
            const opt = Choices.defaults.templates.option.call(this, { label, value, customProperties, active, disabled })

            const originalOption = this._presetOptions.filter((option) => option.value === value)[0]

            if (originalOption) {
              const dataset = originalOption.dataset || {}

              for (const [dataProperty, dataValue] of Object.entries(dataset)) {
                opt.dataset[dataProperty] = typeof dataValue === 'string' ? dataValue : undefined
              }
            }

            return opt
          },
          choice: function (choicesConfig, choice, selectText) {
            const originalOption = this._presetOptions.filter((option) => option.value === choice.value)[0]

            choice.label = get(originalOption, 'dataset.htmlLabel', choice.label)

            return Choices.defaults.templates.choice.call(this, choicesConfig, choice, selectText)
          },
          item: function (choicesConfig, choice, removeItemButton) {
            const originalOption = this._presetOptions.filter((option) => option.value === choice.value)[0]

            choice.label = get(originalOption, 'dataset.htmlLabel', choice.label)

            const item = Choices.defaults.templates.item.call(this, choicesConfig, choice, removeItemButton)

            item.dataset.controller = 'text-truncate'

            return item
          }
        }
      }
    }
  }
}
