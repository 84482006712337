import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {closeAfterPrint: Boolean, triggerOnVisit: Boolean}

  connect() {
    if (this.triggerOnVisitValue) this.triggerPrint()
  }

  triggerPrint() {
    window.focus();
    window.print();
    if (this.closeAfterPrintValue) setTimeout(function () { window.close(); }, 100);
  }
}
