import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['emptyState', 'item']

  connect() {
    this.observer = new window.MutationObserver(this.update.bind(this))
    this.observer.observe(this.element, { childList: true, attributes: false, subtree: true })
    this.update()
  }

  disconnect() {
    this.observer.disconnect()
  }

  update() {
    this.emptyStateTarget.classList.toggle('d-none', this.itemTargets.length !== 0)
  }
}
