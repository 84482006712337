import { Controller } from '@hotwired/stimulus'
import { useDebounce } from 'stimulus-use'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['query']
  static debounces = ['search']

  connect() {
    useDebounce(this, { wait: 500 })

    const i = setInterval(() => {
      this.queryTooltip = bootstrap.Tooltip.getInstance(this.queryTarget)

      if (this.queryTooltip) {
        this.queryLength = this.queryTarget.value.length
        this.queryTooltip.hide()
        clearInterval(i)
      }
    }, 50)
  }

  setFilter(e) {
    if (e.target === this.queryTarget) {
      this.#toggleQueryTooltip()

      // Search only if query >= 3 characters or previous query was longer than current (clear query filter)
      if (this.queryTarget.value.length < 3 && this.queryLength < this.queryTarget.value.length) {
        return
      }
    }

    this.search()
    this.queryLength = this.queryTarget.value.length
  }

  search() {
    this.element.requestSubmit()

    if (this.element.tagName.toLowerCase() === 'form' && this.element.method?.toLowerCase() === 'get') {
      const url = new URL(this.element.action)
      const formData = new FormData(this.element)

      url.search = new URLSearchParams(formData)

      window.history.pushState({}, null, url.toString());
    }
  }

  #toggleQueryTooltip() {
    if (!this.queryTooltip) return

    if (this.queryTarget.value.length < 3) {
      this.queryTooltip.enable()
      this.queryTooltip.show()
    } else {
      this.queryTooltip.hide()
      this.queryTooltip.disable()
    }
  }
}
