import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="password-complexity"
export default class extends Controller {
  static targets = ["helpersContainer", "input", "lengthDisplay", "lettersDisplay", "numbersDisplay", "specialDisplay"]
  static values = {minLength: {type: Number, default: 8}}

  connect() {
    this.evalLength();
    this.evalLetters();
    this.evalNumbers();
    this.evalSpecial();
  }

  handleInput() {
    this.helpersContainerTarget.classList.toggle("d-none", false)
    this.evalLength();
    this.evalLetters();
    this.evalNumbers();
    this.evalSpecial();
  }

  evalLength() {
    this.lengthDisplayTarget.querySelector("[data-state=true]").classList.toggle("d-none", this.inputTarget.value.length < this.minLengthValue)
    this.lengthDisplayTarget.querySelector("[data-state=false]").classList.toggle("d-none", this.inputTarget.value.length >= this.minLengthValue)
  }

  evalLetters() {
    const re = /\p{L}/u
    this.lettersDisplayTarget.querySelector("[data-state=true]").classList.toggle("d-none", !re.test(this.inputTarget.value))
    this.lettersDisplayTarget.querySelector("[data-state=false]").classList.toggle("d-none", re.test(this.inputTarget.value))
  }

  evalNumbers() {
    const re = /[0-9]/;
    this.numbersDisplayTarget.querySelector("[data-state=true]").classList.toggle("d-none", !re.test(this.inputTarget.value))
    this.numbersDisplayTarget.querySelector("[data-state=false]").classList.toggle("d-none", re.test(this.inputTarget.value))
  }

  evalSpecial() {
    const re = /[^0-9A-Za-z]/;
    this.specialDisplayTarget.querySelector("[data-state=true]").classList.toggle("d-none", !re.test(this.inputTarget.value))
    this.specialDisplayTarget.querySelector("[data-state=false]").classList.toggle("d-none", re.test(this.inputTarget.value))
  }
}
