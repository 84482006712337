import { Application } from "@hotwired/stimulus"
import { Tooltip } from "bootstrap";

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

export { application }

Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach((elm) => {
  return new Tooltip(elm)
})
