import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {locale: String}

  connect() {
    window.chatwootSettings = {
      locale: document.documentElement.lang || "en"
    }
    const BASE_URL = "https://app.chatwoot.com";
    const g = document.createElement("script"), s = document.getElementsByTagName("script")[0];
    g.src = BASE_URL + "/packs/js/sdk.js";
    g.defer = true;
    g.async = true;
    s.parentNode.insertBefore(g, s);
    g.onload = function () {
      window.chatwootSDK.run({
        websiteToken: 'WVGmmwAK9saJtALya7DGhZs7',
        baseUrl: BASE_URL
      })
    }
    this.g = g
  }

  disconnect() {
    window.$chatwoot = undefined
    window.chatwootSDK = undefined
    this.g.remove()
  }

}
