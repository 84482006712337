import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["vatNumber", "countrySelect", "fetchBtn"]
  static values = {url: String, idPrefix: String, validVies: Array}

  connect() {
    this.handleChange()
  }

  isActive() {
    const vatNumber = this.vatNumberTarget.value
    const viesCode = this.countrySelectTarget.selectedOptions[0].dataset.viesCode
    return this.validViesValue.includes(viesCode) && vatNumber && vatNumber.trim() !== ""
  }

  handleChange() {
    this.fetchBtnTarget.classList.toggle("active", this.isActive())
  }

  fetch() {
    const vatNumber = this.vatNumberTarget.value
    const viesCode = this.countrySelectTarget.selectedOptions[0].dataset.viesCode
    if (this.isActive()) {
      let url = new URL(this.urlValue)
      let searchParams = url.searchParams;
      searchParams.set('id_prefix', this.idPrefixValue);
      searchParams.set('vat_number', vatNumber);
      searchParams.set('vies_code', viesCode);
      url.search = searchParams.toString()

      fetch(url.toString(), {
        headers: {
          Accept: "text/vnd.turbo-stream.html",
        },
      }).then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html))
    }
  }
}
