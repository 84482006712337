import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static values = {active: String}
  static targets = ["toggleBtn", "toggleText", "toggleValue"]

  connect() {
    this.toggleBtnTargets.forEach(btn => {
      btn.addEventListener("click", (e) => this.handleToggleClick(e))
      if (btn.classList.contains("active")) this.activeValue = btn.dataset["value"]
    })
    this.toggleText()
    this.toggleValue()
  }

  handleToggleClick(e) {
    this.toggleBtnTargets.forEach(btn => {
      btn.classList.toggle("active", btn === e.target)
    })
    this.activeValue = e.target.dataset["value"]
    this.toggleText()
    this.toggleValue()
  }

  toggleText() {
    this.toggleTextTargets.forEach(elem => {
      elem.innerText = elem.dataset[this.activeValue]
    })
  }

  toggleValue() {
    this.toggleValueTargets.forEach(elem => {
      elem.value = elem.dataset[this.activeValue]
    })
  }
}
