import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('keydown', this.preventEvent.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('keydown', this.preventEvent.bind(this))
  }

  preventEvent(e) {
    // Does not disable event for textarea

    if (["Enter", "NumpadEnter"].includes(e.code) && e.target.type !== 'textarea') {
      e.stopPropagation()
      e.preventDefault()
      return false
    }
  }
}
