import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["btn"]

  connect() {
    this.element.addEventListener("turbo:submit-start", () => {
      this.submitTriggered()
    })

    this.element.addEventListener("turbo:submit-end", () => {
      this.submitFinished()
    })
  }

  submitTriggered() {
    this.btnTargets.forEach(btn => {
      btn.dataset.originalHTML = btn.innerHTML
      btn.innerHTML = btn.dataset.disableWith
      btn.disabled = true
    })
    this.origFormPointerEvents = this.element.style.pointerEvents
    this.element.style.pointerEvents = "none"
  }

  submitFinished() {
    this.btnTargets.forEach(btn => {
      btn.innerHTML = btn.dataset.originalHTML
      btn.disabled = false
    })
    this.element.style.pointerEvents = this.origFormPointerEvents
  }
}
