import { Controller } from "@hotwired/stimulus"
import { Tooltip } from "bootstrap"

export default class extends Controller {
  connect() {
    this.element.classList.add('text-truncate')
    if (this.element.tagName.toLowerCase() === 'td' && !this.element.style.maxWidth) {
      this.element.style.maxWidth = 0
    }
    this.element.addEventListener('mouseenter', this.showTooltip.bind(this))
  }

  disconnect() {
    this.disposeTooltip()

    this.element.removeEventListener('mouseenter', this.showTooltip.bind(this))
  }

  showTooltip() {
    if (this.element.offsetWidth < this.element.scrollWidth) {
      Tooltip.getOrCreateInstance(this.element, { title: this.element.title || this.element.innerHTML, html: true }).show()
    } else {
      this.disposeTooltip()
    }
  }

  disposeTooltip() {
    Tooltip.getInstance(this.element)?.dispose()
  }
}
