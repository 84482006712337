import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="password-hide-show"

export default class extends Controller {
  static targets = [ "password", "eyeIcon", "eyeSlashIcon" ]

  toggle() {
    const passwordField = this.passwordTarget

    if (passwordField.type === "password") {
      passwordField.type = "text"
      this.eyeSlashIconTarget.classList.toggle("d-none", true)
      this.eyeIconTarget.classList.toggle("d-none", false)
    } else {
      passwordField.type = "password"
      this.eyeSlashIconTarget.classList.toggle("d-none", false)
      this.eyeIconTarget.classList.toggle("d-none", true)
    }
    passwordField.focus()
  }
}
