import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static values = { baseUrl: String }

  setTab(e) {
    let url = new URL(window.location.href)
    let searchParams = url.searchParams;
    searchParams.set('active', e.target.value);
    url.search = searchParams.toString()
    window.location.href = url.toString()
  }
}
